import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import {Progress} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {API_URL} from '../config';
import 'bootstrap/dist/css/bootstrap.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameClient: '',
      phoneClient: '',
      selectedFile: null,
      loaded: 0,
      isUploading: false,
      isLoaded: false
    }
  }

  checkMimeType = (event) => {
    //getting file object
    let files = Array.from(event.target.files);
    //define message container
    let err = [];
    // list allow mime type
    const types = ['image/png', 'image/jpeg'];

    // // loop access array
    files.forEach((file, i) => {
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err[i] = file.type + ' цей формат не підтримується\n';
      }
    });
    if (err.length) {
      return err.forEach((error) => {
        toast.error(error);
        event.target.value = null
      })
    }
    return true;
  };
  maxSelectFile = (event) => {
    //let files = event.target.files;
    // if (files.length > 3) {
    //   const msg = 'Only 3 images can be uploaded at a time'
    //   event.target.value = null;
    //   toast.warn(msg);
    //   return false;
    // }
    return true;
  };
  checkFileSize = (event) => {
    let files = event.target.files;
    let size = 40 * (1024 * 1024); //
    let err = [];
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + 'розмір файлу великий, будь ласка зменшіть його\n';
      }
    }
    for (let z = 0; z < err.length; z++) {// if message not same old that mean has error
      // discard selected file
      toast.error(err[z]);
      event.target.value = null;
    }
    return true;
  };
  onChangeHandler = event => {
    let files = event.target.files;

    if (this.checkMimeType(event) && this.checkFileSize(event)) { //this.maxSelectFile(event) &&
      // if return true allow to setState
      this.setState({
        selectedFile: files,
        loaded: 0,
      })
    }
  };

  onFocusName = e => {
    this.setState({nameClient: e.target.value});
  };

  onFocusPhone = e => {
    this.setState({phoneClient: e.target.value});
  };

  onClickHandler =  e => {
    const data = new FormData();

    for (let x = 0; x < this.state.selectedFile.length; x++) {
      data.append('file', this.state.selectedFile[x]);
    }
    console.log(data.getAll('file'));
    axios.post(`${API_URL}/image-upload`, data, {
      params: {
        nameClient: this.state.nameClient,
        phoneClient: this.state.phoneClient
      },
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
          isUploading: true
        });
      },
    })
        .then(res => { // then print response status
          console.log(res);
          toast.success('Ураа! Завантажено! ;)');
          this.setState({
            isLoaded: true
          });
        })
        .catch(err => { // then print response status
          this.setState({
            isUploading: false,
            isLoaded: false
          });
          toast.error('Помилка щось пішло не так! Спробуйте ще!')
        });
    e.preventDefault();
  };

  render() {
    return (
        <div className="container">
          <div className="row">
            <div className="offset-md-3 col-md-6">
              <ToastContainer/>
              <br/>
              <br/>
              <div className={this.state.isLoaded ? '' : 'd-none'}>
                <h2 className="text-center">
                  <FontAwesomeIcon icon={faCheckCircle} color="green" /> Завантажено!
                  <br/>
                </h2>
                <br/>
                <h3 className="text-center">Подзвонити <br/> <a href="tel:+380977009782">+38(097) 700 97 82</a></h3>
              </div>
              <div className={this.state.isUploading && !this.state.isLoaded ? '' : 'd-none'}>
                <h2 className="text-center">Завантажується... Зачекайте! <FontAwesomeIcon icon={faCoffee} color="green" /></h2>
                <div className="form-group">
                  <Progress max="100" color="success"
                            value={this.state.loaded}>{Math.round(this.state.loaded, 2)}%</Progress>
                 </div>
              </div>

              <div className={this.state.isUploading ? 'd-none' : ''}>
                <p className='text-center'>Друкуй фотографії без втрати якості!</p>
                <form className="form" encType="multipart/form-data">

                  <div className="form-group">
                    <label>Ім'я *</label>
                    <input type="text" className="form-control" onBlur={this.onFocusName} required/>
                  </div>
                  <div className="form-group">
                    <label>Номер телефону *</label>
                    <input type="tel" className="form-control" onBlur={this.onFocusPhone} required/>
                  </div>
                  <div className="form-group files">
                    <label>Оберіть фотографії </label>
                    <input type="file" className="form-control-file" multiple onChange={this.onChangeHandler} required/>
                  </div>
                  <button type="button"
                          className="btn btn-lg btn-success btn-block"
                          onClick={this.onClickHandler}
                          disabled={!this.state.nameClient || !this.state.phoneClient || !this.state.selectedFile}>Завантажити
                  </button>

                </form>
              </div>


            </div>
          </div>
        </div>
    );
  }
}

export default App;